// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import flatpickr from 'flatpickr'

require('stylesheets/application.css')
Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener('turbolinks:load', () => {
  var can = document.getElementById('myChart')
  if(can){
    var ctx = can.getContext('2d');
    var chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: JSON.parse(ctx.canvas.dataset.labels),
        datasets: [{
          label: '= Temprature(°C)',
          data: JSON.parse(ctx.canvas.dataset.data),
          borderColor: 'blue',
          fill: false,
          backgroundColor: 'blue'
        }]
      },
      options:{
       scales: {
          y: {
            max: 20
          }
        },
        responsive: true,
        plugins: {
          title:{
            display: true,
            text: '* All times shown in Pacific Standard Time',
            position: 'bottom'
          },
          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              boxWidth: 50,
              textAlign: 'left'
            }
          }
        }
      }
    });
    const containerBody = document.querySelector('.chartClass');
    if(chart.data.labels.length > 10){
      containerBody.style.width = '2500px'
    }
  }
})
